@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;700&display=swap");

:root {
  --yellow: #f9f871;
  --dark-text: #2f4858;
  --green: #039590;
  --gray-text: #111827bf;
  --hero-gradient: linear-gradient(180deg, #039590 0%, #4bbc8e 100%);
  --form-gradient: linear-gradient(87.83deg, #1aa28f 1.52%, #49bb8e 80.89%);
  --green-card: #78cdaa;
  --light-text: #ffffffd3;
  --copyright-background: #1c6e7d;
}

img {
  max-width: 100%;
}

ul,
ol,
li {
  list-style-type: none;
}

button {
  user-select: none;
  font-weight: 700;
}

body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 36px;
  line-height: 2.5rem;
  color: #fff;
  font-weight: 400;
  margin-bottom: 1.25rem;
}

h2 {
  font-size: 2rem;
  line-height: 3rem;
  margin-bottom: 1.25rem;
  color: #fff;
  font-weight: 700;
}

h2.green {
  color: var(--green);
  text-align: center;
  font-size: 2.3rem;
}

h3.green {
  color: var(--green);
}

h3 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2.25rem;
}

p {
  line-height: 1.8;
  color: var(--gray-text);
}

.btn-yellow {
  background-color: var(--yellow);
  color: var(--dark-text);
  padding: 0.625rem 2.25rem;
  border: 2px solid var(--yellow);
  transition: all 0.3s ease-in-out;
}

.btn-yellow:hover {
  background-color: #e0e050;
  cursor: pointer;
  border: 2px solid #e0e050;
}

.btn__request__demo {
  background-color: var(--yellow);
  color: var(--green);
  font-weight: 700;
  padding: 0.625rem 2.25rem;
  border: 2px solid var(--yellow);
  transition: all 0.3s ease-in-out;
}

.btn__request__demo:hover {
  background-color: #e0e050;
  cursor: pointer;
  border: 2px solid #e0e050;
}

.btn__ p {
  line-height: 1.8;
  font-size: 1rem;
}

.button__slides {
  border: 2px solid var(--green);
  padding: 0.625rem 2rem;
  border-radius: 30px;
  color: var(--green);
}

.active {
  background-color: var(--green);
  color: #fff;
}

p.gray {
  color: var(--gray-text);
}

.light-text {
  color: var(--light-text);
}

.navbar {
  position: absolute;
  left: 0;
  right: 0;
  top: -1000%;
  width: 100%;
  padding: 2rem 0;
  background: var(--hero-gradient);
}

.navbar.open {
  top: 80px;
  transition: all 0.3s ease-in-out;
}

.hero {
  padding: 7rem 1.25rem;
}

.section {
  padding: 2.5rem 1.25rem;
}

.gradient {
  background: var(--hero-gradient);
}

.form__gradient {
  background: var(--form-gradient);
}

.green-card {
  background-color: var(--green-card);
  padding: 1.25rem;
}

.footer__dark {
  background-color: var(--copyright-background);
}

.back__office__hero {
  border-top: 2px solid var(--yellow);
}

.arrow {
  position: absolute;
  width: 100px;
  margin-top: 4rem;
}

.arrow-1 {
  left: 35%;
  transform: translateX(-35%);
  transform: rotate(90deg);
}

.arrow-2 {
  left: 45%;
  transform: translateX(-45%);
  transform: rotate(270deg);
}

.social__icon {
  color: var(--yellow);
}

.image__shadow {
  box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.3);
}

@media (min-width: 768px) {
  .tech__web__article {
    display: flex;
    align-items: center;
  }

  .tech__web__article .text {
    width: 130%;
  }

  .tech__web__article:nth-child(odd) {
    flex-direction: row-reverse;
  }

  .home__article:nth-child(odd) {
    flex-direction: row;
  }

  .home__article:nth-child(even) {
    flex-direction: row-reverse;
  }

  .tech__web__article:nth-child(odd) p {
    margin-right: 2rem;
  }

  .home__article:nth-child(even) p {
    margin-left: 2rem;
    margin-right: 0;
  }

  .tech__web__article:nth-child(even) div.text {
    margin-left: 2rem;
  }

  .home__article:nth-child(even) div.text {
    margin-right: 2rem;
  }

  .home__article:nth-child(odd) div.text {
    margin-left: 2rem;
  }
}

@media (min-width: 850px) {
  .tech__web__article .text {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .header {
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 1.25rem;
  }

  .navbar {
    top: 0;
    right: 20px;
    background: transparent;
    padding-right: 1.25rem;
  }

  .hero {
    padding: 12rem 1.25rem 5rem;
  }

  .section {
    padding: 5rem 1.25rem;
  }

  h1 {
    font-size: 3.75rem;
    line-height: 1;
  }

  h2 {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  h2.green {
    font-size: 3rem;
  }

  p {
    font-size: 1.125rem;
  }

  .home__cards {
    height: calc(80vh - 10px);
    margin-bottom: 2rem;
  }

  .home__cards__two {
    position: absolute;
  }

  .home__cards__two:first-child {
    left: 5rem;
    top: 2rem;
  }

  .home__cards__two:nth-child(2) {
    right: 5rem;
    top: 2rem;
  }

  .home__cards__two:nth-child(3) {
    left: 5rem;
    top: calc(55vh - 40px);
  }

  .home__cards__two:nth-child(4) {
    left: 50%;
    transform: translateX(-50%);
    top: calc(30vh - 1px);
  }

  .home__cards__two:nth-child(5) {
    right: 5rem;
    top: calc(55vh - 40px);
  }

  .article__width {
    width: 550px;
    margin-bottom: 2.5rem;
    height: 300px;
  }

  .tech__web__article .text {
    width: 80%;
  }

  .trade__cards {
    height: calc(55vh - 10px);
  }

  .crm {
    position: absolute;
    bottom: -250px;
  }

  .arrow {
    top: 0;
  }

  .arrow img {
    width: 200px;
  }

  .arrow-2 {
    transform: rotate(360deg);
    top: -10%;
  }

  .arrow-3 {
    transform: rotate(130deg);
    top: 100%;
    left: 0;
    margin-left: 0;
    margin-top: 3.5rem;
  }

  .arrow-4 {
    transform: rotate(-50deg);
    top: 100%;
    left: 10%;
    margin-left: 0;
    margin-top: 3.5rem;
  }

  .arrow-5 {
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }

  .arrow-6 {
    top: 35%;
    left: 45%;
    transform: translateX(-45%);
    transform: rotate(180deg);
  }
}

@media screen and (min-width: 1024px) and (min-height: 500px) {
  .home__cards {
    height: 32rem;
  }
}

@media screen and (min-width: 1024px) and (min-height: 575px) {
  .home__cards {
    height: 35rem;
  }
}

@media screen and (min-width: 1024px) and (min-height: 670px) {
  .home__cards {
    height: 38rem;
  }
}

@media screen and (min-width: 1024px) and (min-height: 750px) {
  .home__cards {
    height: 40rem;
  }
}

@media screen and (min-width: 1024px) and (min-height: 800px) {
  .home__cards {
    height: 43rem;
  }
}

@media screen and (min-width: 1280px) and (min-height: 750px) {
  .home__cards {
    height: 42rem;
  }
}

@media screen and (min-width: 1024px) and (min-height: 1366px) {
  .home__cards {
    height: 45rem;
  }

  .home__cards__two:first-child {
    top: 0rem;
  }

  .home__cards__two:nth-child(2) {
    right: 8rem;
    top: 0rem;
  }

  .home__cards__two:nth-child(3) {
    top: 30rem;
  }

  .home__cards__two:nth-child(4) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .home__cards__two:nth-child(5) {
    top: 30rem;
  }

  .trade__cards {
    height: 28rem;
  }
}

@media screen and (min-width: 1280px) and (min-height: 800px) {
  .home__cards {
    height: 43rem;
  }
}

@media (min-width: 1280px) {
  .navbar {
    padding-right: 0;
  }

  .header {
    padding: 1.25rem 0;
  }

  .hero {
    padding: 12rem 0 5rem;
  }

  .section {
    padding: 5rem 0;
  }

  .max-width {
    max-width: 80rem;
    margin: 0 auto auto;
  }

  .cards__max__width {
    max-width: 60rem;
    margin: 0 auto auto;
  }

  .tech__web__article .text {
    width: 60%;
  }

  .tech__web__article:nth-child(odd) p {
    margin-right: 5rem;
  }

  .tech__web__article:nth-child(even) p {
    margin-left: 0;
  }

  .tech__web__article:nth-child(even) div.text {
    margin-left: 5rem;
  }

  .home__article:nth-child(even) div.text {
    margin-right: 5rem;
    margin-left: 0;
  }

  .home__article:nth-child(odd) div.text {
    margin-left: 5rem;
  }

  .home__cards__two:first-child {
    left: 8rem;
  }

  .home__cards__two:nth-child(2) {
    right: 8rem;
  }

  .home__cards__two:nth-child(3) {
    left: 8rem;
  }

  .home__cards__two:nth-child(5) {
    right: 8rem;
  }
}

@media screen and (min-width: 1440px) and (min-height: 600px) {
  .home__cards {
    height: 39rem;
  }
}

@media screen and (min-width: 1440px) and (min-height: 700px) {
  .home__cards {
    height: 40rem;
  }
}

@media screen and (min-width: 1440px) and (min-height: 750px) {
  .home__cards {
    height: 42rem;
  }
}

@media screen and (min-width: 1440px) and (min-height: 800px) {
  .home__cards {
    height: 44rem;
  }
}

@media screen and (min-width: 1440px) and (min-height: 850px) {
  .home__cards {
    height: 46rem;
  }
}
